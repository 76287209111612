import React, { useState } from "react";
import Reveal from "react-reveal";
import Layout from "../components/layouts";
import woywLogo from "../images/woyw-logo.png";
import woyw2 from "../images/woyw-2.png";
import woyw3 from "../images/woyw-3.png";
import woyw4 from "../images/woyw-4.png";
import woyw5 from "../images/woyw-5.png";
import woyw6 from "../images/woyw-6.jpg";

const lesswatts = () => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [feedback, setFeedback] = useState({
    message: "",
    type: null,
  }); 
  const messageClasses = `contact-message ${feedback.type}`;

  const scrollToSignup = () => {
    window.scrollTo(0, document.getElementById("form").offsetTop);
  };

  const handleSubmit = ((e) => {
    e.preventDefault();
    const formData  = new FormData();

    for(const name in e) {
      formData.append(name, e[name]);
    }

    if (fName !== "" && lName !== "" && email !== "") {
        let regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        if (!regex.test(email)) {
          setFeedback({
            message: 'Please enter a valid E-mail',
            type: 'error'
          });
          return;
        } else {
          fetch("https://globalinheritance.us2.list-manage.com/subscribe/post-json?&c=?", {
            method: "POST",
            body: JSON.stringify(formData),
            mode: "no-cors",
          }).then(() => {
            setFeedback({
              message: 'Thank you! Your information has been sent',
              type: 'success'
            });
          }).catch((error) => {
            setFeedback({
              message: error.message,
              type: "error",
            });
          });
        }
    } else {
      setFeedback({
        message: 'Please fill out all fields',
        type: 'error'
      });
    }
  });

    return (
      <Layout>
      <div className="woyw-page">
                <div className="woyw-header">
                  <img src={woywLogo} />
                </div>
                <div className="woyw-inner">
                  <div className="woyw-description">
                      <h1>Get Your Power Bill In Shape with Les Watts!</h1>
                      <p>Are you ready to cut out those extra kilowatts and get your home looking and feeling good? Les Watts's home workout video is now available online for free. In under 4 minutes, Les can show you how to reduce your power by up to 60%! In a matter of hours, you can start showing off your slimmed down power meter to neighbors and friends while saving money to purchase that new street bike. These exercises are guaranteed to reduce your energy consumption and collectively our consumption as a planet.</p>
                      <p>Produced by Global Inheritance, Work Out Your Watts is an energy saving initiative designed to teach you the best exercises in reducing your carbon footprint and improving planetary health. As a result of COVID-19, commuting and the commercial sector shrank as more people worked and studied remotely. From increased screen time, to cooking at home, around-the-clock heaters, and air conditioners - excessive energy is being consumed, and home energy consumption has skyrocketed! Add in the economic and energy impacts of the war in the Ukraine, and households are seeing their energy bill continue to rise. What can you do? <a href="" onClick={scrollToSignup}>Join our WOYW gym</a> today to rethink your energy consumption while receiving the type of training needed to reduce your home energy bill. </p>
                      <p>The WOYW gym is free to join and offers energy saving tips, work out videos and information on upcoming events. Tell them "Les Watts" sent you and receive a free reusable water bottle (while supplies last).</p>
                  </div>
                  <div className="woyw-body">
                    <div className="embed-container">
                      <iframe src="https://www.youtube.com/embed/GEBntUgIlTE" />
                    </div>
                </div>

              </div>
                
                
                <div className="woyw-inner">
                  <h1>Start Saving Money Today by Trying These Exercises at Home</h1>
                  <Reveal effect="animated fadeIn">
                    <div className="woyw-gallery">
                    <div className="woyw-ex">
                      <img src={woyw2} />
                      <p><strong>Exercise #1:</strong> Keep Your Thermostat to 78% on Summer Days</p>
                    </div>
                    <div className="woyw-ex">
                      <img src={woyw3} />
                      <p><strong>Exercise #2:</strong> When You're Not in the Room, Turn the Lights Out</p>
                    </div>
                    <div className="woyw-ex">
                      <img src={woyw4} />
                      <p><strong>Exercise #3:</strong> Check Your Power Meter Once a Year for Power Leaks</p>
                    </div>
                    <div className="woyw-ex">
                      <img src={woyw5} />
                      <p><strong>Exercise #4:</strong> When Traveling, Set Your Thermostat to Vacation Mode</p>
                    </div>
                    </div>
                  </Reveal>
                </div>
                <Reveal effect="animated fadeIn">
                <div className="woyw-inner">
                  <div className="woyw-description">
                    <h1>Reading Your Home Power Meter</h1>
                      <p>Participate in Les Watts's annual home physical to make sure your maximizing your home workout and not burning empty kilowatts. This exercise can save you time, money and energy! If this workout gets too difficult, remember you can always call your local power company for a spot! </p>
                  </div>
                  <div className="woyw-body">
                    <a href="https://www.wikihow.com/Read-an-Electric-Meter" target="_blank"><img className="try-it" src={woyw6} /></a>
                  </div>
                </div>
                </Reveal>

                <div className="contact" id="form">
                    <div className="form-container">
                        <div className="form-inner application-form-inner">

                            <h1>Join the WOYW gym today. Your Wallet and Planet Earth Will Thank You! </h1>
                            <form name="applicationForm" className="form application-form app-intern" onSubmit={(e) => handleSubmit(e)}>
                                <input type="hidden" name="u" value="40e700f186279051f3a238f8a"/>
                                <input type="hidden" name="id" value="0704a0a613"/>
                                <input type="hidden" name="TYPE" id="MERGE3" value="INTERN" />
                                <div className="form-field application-field">
                                    <input type="text" name="FNAME"  placeholder="first name" id="MERGE1" value={fName} onChange={(e) => setFname(e.currentTarget.value)} />
                                    <label>FIRST NAME</label>
                                </div>
                                <div className="form-field application-field">
                                    <input type="text" name="LNAME"  placeholder="last name" id="MERGE2" value={lName} onChange={(e)=> setLname(e.currentTarget.value)} />
                                    <label>LAST NAME</label>
                                </div>
                                <div className="form-field application-field">
                                    <input type="email" name="EMAIL"  placeholder="email" id="MERGE0" value={email}  onChange={(e) => setEmail(e.currentTarget.value)} />
                                    <label>EMAIL</label>
                                </div>
                                <div className="form-field application-field">
                                    <input type="text" name="CITY" id="MERGE5" placeholder="city"  value={city}  onChange={(e) => setCity(e.currentTarget.value)} />
                                    <label>Recent Power Meter Reading (Optional)</label>
                                </div>

                                <div className="submit-container">
                                    <button type="submit" className="btn-container">
                                        <div className="btn primary small">
                                        <span>Submit</span>
                                        </div>
                                    </button>
                                </div>

                                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
                                    <label htmlFor="b_name">Name: </label>
                                    <input type="text" name="b_name" tabIndex={-1} value="" placeholder="Freddie" id="b_name"/>

                                    <label htmlFor="b_email">Email: </label>
                                    <input type="email"  name="b_email" tabIndex={-1} value="" placeholder="youremail@gmail.com" id="b_email"/>

                                    <label htmlFor="b_comment">Comment: </label>
                                    <textarea name="b_comment"  tabIndex={-1} placeholder="Please comment" id="b_comment"></textarea>
                                </div>

                                <div className={messageClasses}>{feedback.message}</div>
                            </form>
                            <p>Interested in partnering, sponsoring classes, or volunteering at the gym? Pick up that keyboard now and send us an email - <a href="mailto:LesWatts@globalinheritance.org">LesWatts@globalinheritance.org</a></p>

                        </div>
                    </div>
                        
                    {/* <a href="mailto:pb@globalinheritance.org">Partner</a>
                    <a href="mailto:pb@globalinheritance.org">Ask Questions</a> */}

                </div>
            </div>
            </Layout>
    )
}

export default lesswatts;


